import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TitleBox from '../TitleBox/TitleBox';
import useStyles from './Dropdown.styles';

const Dropdown = ({ title, itemData }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <>
      <TitleBox title={title} />
      <Box className={classes.box}>
        {itemData.map((item, index) => {
          return (
            <Container key={item} className={classes.root}>
              <Accordion
                className={classes.accordion}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  className={classes.accordionSummaryRoot}
                  classes={{
                    content: classes.content,
                    expanded: classes.expanded,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className={classes.questionBox}>
                    <span className={classes.span} />
                    <Typography variant="h5" className={classes.questiontext}>
                      {item.question}
                    </Typography>
                  </Box>
                </AccordionSummary>

                <Box className={classes.answerBox}>
                  <Container>{item.answer}</Container>
                </Box>
              </Accordion>
            </Container>
          );
        })}
      </Box>
    </>
  );
};

export default Dropdown;
