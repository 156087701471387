import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import { Tabs, Container, Typography, Tab, Box } from '@material-ui/core';
import useStyles from './NavHorizontalTab.styles';
import TitleBox from '../TitleBox/TitleBox';
import ROUTES from '../../constants/routes';
import { useHistory } from 'react-router-dom';

const NavHorizontalTab = ({ itemData, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleClick = (index) => {
    setSelectedIndex(index);
    setOpen(true);
    if (selectedIndex == index) {
      setOpen(!open);
    }
  };

  return (
    <>
      {/* map title */}
      {itemData.map((item, index) => {
        return (
          <List key={item}>
            <ListItem
              onClick={() => {
                handleClick(index);
                if (item.routes) {
                  onClose();
                  history.push({
                    pathname: item.routes,
                  });
                }
              }}
              onClose={onClose}
              classes={{
                root: classes.title,
                selected: classes.selected,
              }}
              selected={index == selectedIndex}
            >
              <Typography
                variant="h6"
                textAlign="center"
                className={classes.title}
              >
                {item.title}
              </Typography>
            </ListItem>
            <Box className={item.description ? classes.box : null}>
              {/* loop each of the description */}
              {index == selectedIndex &&
                item.description &&
                item.description.map((des) => {
                  return (
                    <Collapse key={item} in={open} unmountOnExit>
                      <ListItem button className={classes.nested}>
                        <ListItemText
                          primary={des.subtitle}
                          classes={{
                            root: classes.text,
                          }}
                          onClick={() => {
                            onClose();
                            history.push({
                              pathname: des.route,
                            });
                          }}
                        />
                      </ListItem>
                    </Collapse>
                  );
                })}
            </Box>
          </List>
        );
      })}
    </>
  );
};

export default NavHorizontalTab;
