import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
    },
    span: {
      backgroundColor: 'rgba(202, 34, 40, 1)',
      display: 'block',
      width: '10px',
      height: '10px',
    },
    text: {
      textAlign: 'left',
      paddingLeft: 10,
    },
  }),
);
