import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    title: {
      color: '#FFFFFF',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    titleBar: {
      background: 'transparent',
    },
    headerRoot: {
      flexGrow: 1,
      maxWidth: 500,
      alignItems: 'flex-start',
    },
    ImageStyle: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    icon: {
      fontSize: 13.5,
      paddingTop: 20,
    },
  }),
);
