import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    nested: {
      padding: theme.spacing(0),
    },
    mainTitle: {
      color: '#000000',
      padding: 20,
    },
    title: {
      display: 'block',
      textAlign: 'center',
      color: '#000000',
      padding: 15,
    },
    selected: {
      backgroundColor: '#F1F1F1',
    },
    text: {
      color: '#000000',
      padding: 20,
      textAlign: 'center',
      margin: 1,
    },
    box: {
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.25)',
    },
  }),
);
