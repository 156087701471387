import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from '../constants/routes';
import Dashboard from '../pages/dashboard';
import Audit from '../pages/audit';
import ContactUs from '../pages/contactus';
import Tax from '../pages/tax';
import Team from '../pages/team';
import CorpSec from '../pages/corpsec';
import Outsourcing from '../pages/outsourcing';
import ScrollToTop from '../hooks/useScrollToTop';

const Routes = () => {
  return (
    <Suspense fallback={<h1>loading</h1>}>
      <ScrollToTop>
        <Switch>
          <Route exact path={ROUTES.DASHBOARD}>
            <Dashboard />
          </Route>
          <Route exact path={ROUTES.AUDIT}>
            <Audit />
          </Route>
          <Route exact path={ROUTES.TAX}>
            <Tax />
          </Route>
          <Route exact path={ROUTES.CORPSEC}>
            <CorpSec />
          </Route>
          <Route exact path={ROUTES.CONTACTUS}>
            <ContactUs />
          </Route>
          <Route exact path={ROUTES.TEAM}>
            <Team />
          </Route>

          <Route exact path={ROUTES.OUTSOURCING}>
            <Outsourcing />
          </Route>
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
};

export default Routes;
