import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import computerImg from '../assets/computer.png';
import useBreakpoints from '../hooks/useBreakpoints';
import useStyles from './Features2.styles';
import bgImg from '../assets/background/homebg.png';

const Features = ({ textTitle, textDesc, styles, overlay, gradient }) => {
  const { smBelow, xsBelow } = useBreakpoints();
  const classes = useStyles();
  return (
    <Box className={classes.bgrootCentered}>
      <Box
        style={{ paddingLeft: 24, paddingRight: 24 }}
        display={smBelow ? 'initial' : 'flex'}
        alignItems="center"
      >
        <Box>
          <Typography
            style={{ paddingTop: smBelow ? 100 : 0 }}
            variant={smBelow ? 'subtitle1' : 'h6'}
            align="left"
          >
            {textTitle}
          </Typography>
          <Typography
            variant={smBelow ? 'h5' : 'h4'}
            align="left"
            style={{ paddingTop: 20, fontWeight: 600 }}
          >
            {textDesc}
          </Typography>
        </Box>
        <img
          src={computerImg}
          style={{
            maxHeight: smBelow ? (xsBelow ? 200 : 300) : 'initial',
            paddingTop: 20,
          }}
        />
      </Box>
      <div
        className={classes.background}
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
      />
      {overlay && <div className={classes.backdrop} />}
      {gradient && <div className={classes.gradient} />}
      <div
        className={classes.background}
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
      />
    </Box>
    // <Container style={styles}>
    // <Box display={smBelow ? 'initial' : 'flex'} alignItems="center">
    //   <Box>
    //     <Typography variant={smBelow ? 'subtitle1' : 'h6'} align="left">
    //       {textTitle}
    //     </Typography>
    //     <Typography
    //       variant={smBelow ? 'h5' : 'h4'}
    //       align="left"
    //       style={{ paddingTop: 20, fontWeight: 600 }}
    //     >
    //       {textDesc}
    //     </Typography>
    //   </Box>
    //   <img
    //     src={computerImg}
    //     style={{
    //       maxHeight: smBelow ? (xsBelow ? 200 : 300) : 'initial',
    //       paddingTop: 20,
    //     }}
    //   />
    // </Box>
    // <div
    //   className={classes.background}
    //   style={{
    //     backgroundImage: `url(${bgImg})`,
    //   }}
    // />
    // </Container>
  );
};

export default Features;
