import React from 'react';
import ImageTitleList from '../components/ImageTitleList/ImageTitleList';
import bgImg from '../assets/background/homebg.png';
import { howCanWeHelp, whatCanWeHelp } from '../constants/list';
import HeroContent from '../components/HeroContent/HeroContent';
import { Box, Container } from '@material-ui/core';
import Features2 from '../components/Features2';

const Dashboard = () => {
  return (
    <Box
      style={{
        backgroundColor: '#CA2228',
      }}
    >
      <Features2
        overlay
        textTitle={
          'To thrive in the new digital era, every organisation must reimagine how their businesses operate, embracing innovation and digital-first models.'
        }
        textDesc={'Let Meyzer360 jumpstart your business in the digital era.'}
        styles={{ paddingTop: 80, paddingBottom: 80 }}
      />
      {/* <HeroContent
        bgImg={bgImg}
        overlay
        textTitle={
          'To thrive in the new digital era, every organisation must reimagine how their businesses operate, embracing innovation and digital-first models.'
        }
        textDesc={'Let Meyzer360 jumpstart your business in the digital era.'}
      /> */}
      <Container style={{ paddingTop: 50 }}>
        <ImageTitleList
          itemData={howCanWeHelp}
          cols={3}
          title="How can we help?"
          size="medium"
          rowHeight={250}
          gradient
        />
      </Container>

      <Container style={{ paddingTop: 60, paddingBottom: 80 }}>
        <ImageTitleList
          itemData={whatCanWeHelp}
          title="What can we help with?"
          size="small"
          rowHeight={200}
          gradient
        />
      </Container>
    </Box>
  );
};

export default Dashboard;
