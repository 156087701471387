import React from 'react';
import {
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@material-ui/core';
import useStyles from './ImageTitleList.styles';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useHistory } from 'react-router-dom';
import TitleBox from '../TitleBox/TitleBox';

const ImageTitleList = ({
  itemData,
  title,
  headerText,
  size,
  overlay,
  gradient,
  gap,
  rowHeight,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { mdBelow } = useBreakpoints();

  return (
    <>
      {!!title && (
        <Typography variant="h4" className={classes.mainTitle}>
          {title}
        </Typography>
      )}
      {!!headerText && <TitleBox title={headerText} />}
      <ImageList
        rowHeight={rowHeight}
        gap={gap !== undefined ? gap : 10}
        cols={size == 'medium' ? (mdBelow ? 1 : 3) : mdBelow ? 2 : 4}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            onClick={() => {
              if (item.route) {
                history.push({
                  pathname: item.route,
                });
              }
            }}
            className={classes.imageItem}
          >
            {overlay && <div className={classes.backdrop} />}
            {gradient && <div className={classes.gradient} />}
            <div
              className={classes.background}
              style={{
                backgroundImage: `url(${item.img})`,
              }}
            />
            <ImageListItemBar
              title={item.title}
              classes={{
                root: classes.titleBar,
                title: overlay ? classes.overlayTitle : classes.title,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default ImageTitleList;
