import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    nested: {
      padding: theme.spacing(0),
    },
    mainTitle: {
      color: '#FFFFFF',
      padding: 20,
    },
    title: {
      display: 'block',
      textAlign: 'center',
      color: '#FFFFFF',
      padding: 15,
    },
    text: {
      backgroundColor: 'rgba(196, 196, 196, 0.2)',
      color: '#FFFFFF',
      padding: 20,
      textAlign: 'center',
      margin: 1,
    },
  }),
);
