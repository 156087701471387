import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Container, Typography, Tab, Box } from '@material-ui/core';
import useStyles from './VerticalTab.styles';
import TitleBox from '../TitleBox/TitleBox';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const VerticalTab = ({ title, itemData }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={classes.root}>
        <TitleBox title={title} />
        <Container className={classes.container}>
          <Typography variant="h6" className={classes.mainTitle}>
            {'Some of our services include but are not limited to:'}
          </Typography>
          <Box className={classes.rootTab}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              classes={{
                indicator: classes.indicator,
                selected: classes.selected1,
              }}
            >
              {/* map title */}
              {itemData.map((item, index) => {
                return (
                  <Tab
                    key={item}
                    label={item.title}
                    className={classes.tabItem}
                    {...a11yProps(index)}
                    classes={{
                      selected: classes.selected1,
                    }}
                  />
                );
              })}
            </Tabs>
            {/* map with full description */}
            {itemData.map((item, index) => {
              return (
                <TabPanel
                  key={item}
                  value={value}
                  index={index}
                  className={classes.box}
                >
                  {/* loop each of the description */}
                  {item.description.map((des, index) => {
                    return (
                      <Box key={des} className={classes.descriptionRow}>
                        <Typography variant="h6" className={classes.text}>
                          {des}
                        </Typography>
                      </Box>
                    );
                  })}
                </TabPanel>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default VerticalTab;
