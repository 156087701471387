import React from 'react';
import useStyles from './IconList.styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import TitleBox from '../TitleBox/TitleBox';
import useBreakpoints from '../../hooks/useBreakpoints';

const IconList = (props) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { itemData, title, headerText } = props;

  return (
    <>
      <Box display="flex" alignItems="flex-start">
        {!!title && <p style={{ color: 'white', fontSize: 25 }}>{title}</p>}
        {!!headerText && <TitleBox title={headerText} />}
      </Box>

      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        {itemData.map((item) => (
          <ButtonBase
            key={item}
            disableRipple
            style={{
              padding: 5,
              width: smBelow ? '33.3%' : '25%', // divide col by 100%
              minHeight: smBelow ? 255 : 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            <Box
              flexDirection="column"
              minHeight="15vh"
              height="100%"
              display="flex"
              alignItems="center"
              color="#FFFFFF"
              paddingTop={10}
            >
              {item.img}
              <Box>
                <Typography variant="body1" className={classes.icon}>
                  {item.title}
                </Typography>
              </Box>
            </Box>
          </ButtonBase>
        ))}
      </Box>
    </>
  );
};

export default IconList;
