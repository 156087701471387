import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Routes from './routes';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer/Footer';

export default function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes />
        <Footer />
      </Router>
    </div>
  );
}
