import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    box: {
      padding: 20,
      backgroundColor: 'rgba(240, 240, 240, 1)',
    },
    title: {
      color: '#000000',
      textAlign: 'left',
      padding: theme.spacing(2),
    },
    titleBar: {
      background: 'transparent',
    },
    headerRoot: {
      flexGrow: 1,
      maxWidth: 500,
      alignItems: 'flex-start',
    },
    ImageStyle: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    button: {
      margin: theme.spacing(1),
    },
    icon: {
      paddingTop: 20,
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
      backgroundPosition: 'center',
    },
    bgroot: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
      [theme.breakpoints.down('sm')]: {
        height: '40vh',
      },
    },
  }),
);
