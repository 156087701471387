import React from 'react';
import DescriptionBox from '../components/DescriptionBox/DescriptionBox';
import compliance_bg from '../assets/background/compliance_bg.jpg';
import MemberList from '../components/MemberList/MemberList';
import VerticalTab from '../components/VerticalTab/VerticalTab';
import HorizontalTab from '../components/HorizontalTab/HorizontalTab';
import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CorporateSecretarialTeam, ourServices } from '../constants/list';
import useBreakpoints from '../hooks/useBreakpoints';

const CorpSec = () => {
  const history = useHistory();
  const { mdBelow } = useBreakpoints();
  return (
    <>
      <Box>
        <DescriptionBox
          img={compliance_bg}
          title="Corporate Secretarial"
          whiteBoxHeight={100}
          header1="Setting Up In Singapore"
          description1={
            <p>
              People set up new companies for one reason: they see a commercial
              opportunity. That is why we believe that company secretarial work
              should be handled by a team that understands your business, and is
              committed to helping your company fulfil its commercial potential.
              <br />
              <br />
              <b>
                Our company secretaries work closely with our accounting and
                consultancy teams, to ensure all your compliance obligations are
                up-to-date. This allows you to focus on your business, in the
                knowledge that your company’s administration is in safe hands.
              </b>
            </p>
          }
        />
      </Box>

      <Box
        style={{
          backgroundColor: 'rgba(51, 51, 51, 1)',
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Container>
          {!mdBelow && (
            <VerticalTab title="Our Services" itemData={ourServices} />
          )}
          {mdBelow && (
            <HorizontalTab
              mainTitle="Our Services"
              mainDes="Some of our services include but are not limited to:"
              itemData={ourServices}
            />
          )}
        </Container>

        {/* <Container style={{ paddingTop: '50px' }}>
          <MemberList
            headerText="Corporate Secretarial Team"
            itemData={CorporateSecretarialTeam}
          />
        </Container> */}
      </Box>
    </>
  );
};

export default CorpSec;
