import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
} from '@material-ui/core';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import onemlogo from '../assets/Meyzer360LogoDarkFont.png';
import MenuImage from '../assets/icons/hamburger-button.png';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import NavHorizontalTab from './NavHorizontalTab/NavHorizontalTab';
import useWindowDimensions from '../hooks/useWindowDimensions';
import ROUTES from '../constants/routes';

const MenuItem = withStyles({
  root: {
    justifyContent: 'center',
  },
})(MuiMenuItem);
// ...later
const headersData = [
  // {
  //   label: 'Team',
  //   href: '/team',
  // },
  //   {
  //     label: 'Insights',
  //     href: '/insights',
  //   },
  {
    label: 'Contact Us',
    href: '/contactus',
  },
];

const mobileHeadersData = [
  {
    title: 'SERVICES',
    description: [
      { subtitle: 'AUDIT & ASSURANCE', route: ROUTES.AUDIT },
      { subtitle: 'CORPORATE SECRETARIAL', route: ROUTES.CORPSEC },
      { subtitle: 'TAX', route: ROUTES.TAX },
      { subtitle: 'OUTSOURCING', route: ROUTES.OUTSOURCING },
    ],
  },
  // {
  //   title: 'TEAM',
  //   routes: ROUTES.TEAM,
  // },
  //   {
  //     label: 'Insights',
  //     href: '/insights',
  //   },
  {
    title: 'CONTACT US',
    routes: ROUTES.CONTACTUS,
  },
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    paddingRight: '79px',
    paddingLeft: '118px',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function NavBar() {
  const { header, logo, menuButton, toolbar } = useStyles();
  const history = useHistory();
  const { height } = useWindowDimensions();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (item, event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    history.push(item);
    // console.log(
    //   'menus',
    //   anchorRef.current,
    //   anchorRef.current.contains(event.target),
    //   item,
    // );
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {onemillenniumLogo}

        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            // onClick={handleToggle}
            onMouseEnter={handleToggle}
            className={menuButton}
          >
            Services
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={(event) => handleClose('audit', event)}
                      >
                        AUDIT & ASSURANCE
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => handleClose('corpsec', event)}
                      >
                        CORPORATE SECRETARIAL
                      </MenuItem>
                      <MenuItem onClick={(event) => handleClose('tax', event)}>
                        TAX
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => handleClose('outsourcing', event)}
                      >
                        OUTSOURCING
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {getMenuButtons()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
        >
          <img src={MenuImage} />
          {/* <MenuIcon /> */}
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div style={{ paddingTop: height / 6 }}>
            <NavHorizontalTab
              itemData={mobileHeadersData}
              onClose={() => {
                handleDrawerClose();
              }}
            />
          </div>
        </Drawer>

        <div>{onemillenniumLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          key={label}
          {...{
            component: RouterLink,
            to: href,
            color: 'black',
            style: { textDecoration: 'none' },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const onemillenniumLogo = (
    <a href="/">
      <img src={onemlogo} height={50} />
    </a>

    // <Typography variant="h6" component="h1" className={logo}>
    //   ONEMILLENNIUM
    // </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            key={label}
            {...{
              key: label,
              color: 'black',
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
