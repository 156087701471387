import React from 'react';
import DescriptionBox from '../components/DescriptionBox/DescriptionBox';
import MemberList from '../components/MemberList/MemberList';
import TitleBox from '../components/TitleBox/TitleBox';
import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import { OurTeam } from '../constants/list';
import team_bg from '../assets/background/team.jpg';
import HeroContent from '../components/HeroContent/HeroContent';

const Team = () => {
  const history = useHistory();
  return (
    <>
      <HeroContent
        bgImg={team_bg}
        gradient
        textTitle={'Our Team'}
        centeredTitle
      />
      <Box
        style={{
          backgroundColor: 'rgba(51, 51, 51, 1)',
          paddingBottom: 50,
        }}
      >
        <Container>
          <MemberList itemData={OurTeam} showIntro />
        </Container>

        <Container
          style={{
            paddingTop: 50,
          }}
        >
          <TitleBox
            title="Contact Us"
            onClick={() => {
              history.push({
                pathname: ROUTES.CONTACTUS,
              });
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default Team;
