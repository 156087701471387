import private_client from '../assets/services/private_client.jpeg';
import business from '../assets/services/business.jpg';
import job from '../assets/services/job.jpg';
import audit from '../assets/services/audit.jpg';
import expat from '../assets/services/expat.jpg';
import finance_advice from '../assets/services/finance_advice.jpg';
import hr from '../assets/services/hr.jpg';
import invest from '../assets/services/invest.jpg';
import outsource from '../assets/services/outsource.jpg';
import start_business from '../assets/services/start_business.jpg';
import tax from '../assets/services/tax.jpg';
import ChikHanLim from '../assets/people/ChikHanLim.png';
import SzeYinAi from '../assets/people/SzeYinAi.png';
import HongDaLaw from '../assets/people/HongDaLaw.png';
import JulianPeh from '../assets/people/JulianPeh.png';
import onem_work1 from '../assets/work/onem_work1.jpeg';
import onem_work2 from '../assets/work/onem_work2.jpeg';
import onem_work3 from '../assets/work/onem_work3.jpeg';
import onem_work4 from '../assets/work/onem_work4.jpeg';
import ROUTES from './routes';
import { Icon } from '@iconify/react';

export const howCanWeHelp = [
  {
    img: private_client,
    title: 'I am a private client',
  },
  {
    img: business,
    title: 'I am in business',
  },
  {
    img: job,
    title: 'I am looking for a job',
  },
];

export const whatCanWeHelp = [
  {
    img: outsource,
    title: 'What can I outsource?',
    route: ROUTES.OUTSOURCING,
  },
  {
    img: audit,
    title: 'I need an audit',
    route: ROUTES.AUDIT,
  },
  {
    img: tax,
    title: 'Help me with tax',
    route: ROUTES.TAX,
  },
  {
    img: expat,
    // title: 'I need expat advice',
    title: 'I want to franchise my product',
  },
  {
    img: finance_advice,
    title: 'I need corporate finance advice',
  },
  {
    img: invest,
    // title: 'How should I invest?',
    title: 'I need help with a merger / acquisition',
  },
  {
    img: start_business,
    title: 'How do I start a business?',
    route: ROUTES.CORPSEC,
  },
  {
    img: hr,
    title: 'I have HR or people issues',
  },
];

export const whyWorkWithONEMillenniumPAC = [
  {
    img: onem_work1,
    title: 'Work with sector experts with our hands-on senior team',
  },
  {
    img: onem_work2,
    title: 'Strong client relationships built on trust',
  },
  {
    img: onem_work3,
    title:
      'Benefit from a single point of contact with all services offered from a single point',
  },
  {
    img: onem_work4,
    title: 'Proactive, responsive joined up approach',
  },
];

export const dotedList = [
  'total annual revenue ≤ $10m;',
  'total assets ≤ $10m;',
  'no. of employees ≤ 50.  ',
  'other requirements (e.g. requirement by MAS, presenting financials to bankers for loans, charities, other stat boards like BCA, STA etc.)',
];

export const OtherAssuranceServices = [
  {
    img: <Icon icon="fluent:vehicle-ship-16-regular" width="60" height="60" />,
    title: 'Overseas funds audits',
  },
  {
    img: <Icon icon="ant-design:area-chart-outlined" width="60" height="60" />,
    title: 'Sales audit',
  },
  {
    img: <Icon icon="ic:outline-rate-review" width="60" height="60" />,
    title: 'Review',
  },
  {
    img: (
      <Icon
        icon="ant-design:delivered-procedure-outlined"
        width="60"
        height="60"
      />
    ),
    title: 'Agreed upon procedures',
  },
  {
    img: <Icon icon="carbon:report" width="60" height="60" />,
    title: 'Due diligence',
  },
  {
    img: <Icon icon="carbon:certificate" width="60" height="60" />,
    title: 'Certification',
  },
];

export const FundAdministrationServices = [
  {
    img: <Icon icon="grommet-icons:launch" width="80" height="80" />,
    title: 'Fund Launch',
    viewBox: '-10 0 100 100',
  },
  {
    img: <Icon icon="bi:calculator-fill" width="80" height="80" />,
    title: 'Portfolio Accounting',
    viewBox: '0 0 60 60',
  },
  {
    img: <Icon icon="ic:outline-rate-review" width="80" height="80" />,
    title: 'Investor Reporting',
    viewBox: '0 0 50 50',
  },
];

export const SupportForYourBusinesses = [
  {
    img: <Icon icon="map:accounting" width="80" height="80" />,
    title: 'Accounting services',
  },
  {
    img: <Icon icon="ant-design:audit-outlined" width="80" height="80" />,
    title: 'Audit',
  },
  {
    img: <Icon icon="heroicons-outline:receipt-tax" width="80" height="80" />,
    title: 'Corporate taxation',
  },
  {
    img: <Icon icon="zmdi:folder-person" width="80" height="80" />,
    title: 'Company secretarial',
  },
  {
    img: <Icon icon="carbon:report" width="80" height="80" />,
    title: 'Regulatory reporting',
  },
  {
    img: <Icon icon="ic:outline-payments" width="80" height="80" />,
    title: 'Payroll',
  },
];

export const AccountingPayrollServices = [
  {
    img: <Icon icon="si-glyph:person-talk" width="80" height="80" />,
    title: 'Accounting advisory',
  },
  {
    img: <Icon icon="si-glyph:customer-support" width="80" height="80" />,
    title: 'Accounting administrative support',
  },
  {
    img: <Icon icon="carbon:report" width="80" height="80" />,
    title: 'Compilation of financial reports',
  },
  {
    img: <Icon icon="cil:speech" width="80" height="80" />,
    title: 'Extensible Business Reporting Language (XBRL)',
  },
  {
    img: <Icon icon="bi:calculator-fill" width="80" height="80" />,
    title: 'Outsourced accounting',
  },
  {
    img: <Icon icon="foundation:torso-business" width="80" height="80" />,
    title: 'Outsourced CFO',
  },
  {
    img: <Icon icon="clarity:form-line" width="80" height="80" />,
    title: 'GST preparation and submission',
  },
  {
    img: <Icon icon="bi:cash-stack" width="80" height="80" />,
    title: 'Monthly payroll preparation',
  },
  {
    img: <Icon icon="si-glyph:paper-shredder" width="80" height="80" />,
    title: 'Disbursement services',
  },
  {
    img: <Icon icon="clarity:talk-bubbles-line" width="80" height="80" />,
    title: 'XBRL services',
  },
];

export const CorporateSecretarialTeam = [
  {
    img: HongDaLaw,
    name: 'Jason Law ',
    position: 'Head of Compliance',
    email: 'cs@1m.com.sg',
    phone: '+65 9682 8188',
    intro:
      'Jason Law holds a Bachelor of Laws (Hons) and has more than 10 years experience in legal and compliance. Hong Da’s experience includes all aspects of corporate secretarial compliance, business establishment and registration, corporate restructuring, and dissolution and cessation of business.  He handles employment issues and liaises with government agencies like the Monetary Authority of Singapore (MAS) and the Singapore Exchange.',
  },
];

export const TaxTeam = [
  {
    img: JulianPeh,
    name: 'Julian Peh',
    position: ' Partner (Legal & Corporate)',
    email: 'julian.peh@meyzergroup.com',
    phone: '+65 8688 8802',
    intro:
      'Chik Han has over 15 years of experience in providing statutory audit and internal controls audit to clients from various industries. His clients include a number of listed companies, multinational and domestic enterprises in a wide range of industries including manufacturing, construction, education, shipping, timber, plantations, IT services and trading companies.   ',
  },
  {
    img: SzeYinAi,
    name: 'Sze Yin, Ai',
    position: 'Managing Partner',
    email: 'szeyin.ai@1m.com.sg',
    phone: '+65 8388 8851',
    intro:
      'Sze Yin graduated from National Technological University (NTU) and has more than 18 years of professional experience covering audit and assurance services to Singapore listed companies (SGX), fund management companies, overseas funds (US GAAP) and emerging companies across a wide array of industries. She has been actively involved in accounting advisory and financial due diligence for merger and acquisition transactions. Sze Yin currently sits on the board of Meyzer Group advising on its venture investments.',
    experience: [
      'Previously a principal inspector in Accounting and Corporate Regulatory Authority (ACRA), Practice Monitoring Department',
      'Represented ACRA and Singapore in overseas meetings with international and regional regulators ',
      'Led audit teams for successful listings of NASDAQ and SGX companies ',
      'Led SOX 404 reviews of major US, European and Japanese MNCs',
    ],
  },
];

export const OurTeam = [
  {
    img: SzeYinAi,
    name: 'Sze Yin, Ai',
    position: 'Managing Partner',
    email: 'szeyin.ai@1m.com.sg',
    phone: '+65 8388 8851',
    intro:
      'Sze Yin graduated from National Technological University (NTU) and has more than 18 years of professional experience covering audit and assurance services to Singapore listed companies (SGX), fund management companies, overseas funds (US GAAP) and emerging companies across a wide array of industries. She has been actively involved in accounting advisory and financial due diligence for merger and acquisition transactions. Sze Yin currently sits on the board of Meyzer Group advising on its venture investments.',
    experience: [
      'Previously a principal inspector in Accounting and Corporate Regulatory Authority (ACRA), Practice Monitoring Department',
      'Represented ACRA and Singapore in overseas meetings with international and regional regulators ',
      'Led audit teams for successful listings of NASDAQ and SGX companies ',
      'Led SOX 404 reviews of major US, European and Japanese MNCs',
    ],
  },
  {
    img: JulianPeh,
    name: 'Julian Peh',
    position: ' Partner (Legal & Corporate)',
    email: 'julian.peh@meyzergroup.com',
    phone: '+65 8688 8802',
    intro:
      'Julian has 20 years of regional experience in the technology and corporate arenas. He graduated with an LLB (Hons) from the National University of Singapore and was involved in financial exits of numerous technology and media companies in Singapore, China and Hong Kong.',
    experience: [
      'Involved in numerous M&A for Fortune 500 companies, public listed companies and leading private companies Led digital transformation processes and restructuring for multiple MNCs',
      'Experience with Intellectual Property (IP) (trademarks, franchises etc) Founded and successfully exited from 4 Technology, Media &Telecommunications (TMT) companies',
    ],
  },
  {
    img: ChikHanLim,
    name: 'Chik Han, Lim',
    position: 'Director, Corporate Services',
    email: 'chikhan.lim@1m.com.sg',
    phone: '+65 6336 6629',
    intro:
      'Chik Han has over 15 years of experience in providing statutory audit and internal controls audit to clients from various industries. His clients include a number of listed companies, multinational and domestic enterprises in a wide range of industries including manufacturing, construction, education, shipping, timber, plantations, IT services and trading companies.   ',
  },
  {
    img: HongDaLaw,
    name: 'Jason Law ',
    position: 'Head of Compliance',
    email: 'cs@1m.com.sg',
    // email: 'jason.law@1m.com.sg',
    phone: '+65 9682 8188',
    intro:
      'Jason Law holds a Bachelor of Laws (Hons) and has more than 10 years experience in legal and compliance. Hong Da’s experience includes all aspects of corporate secretarial compliance, business establishment and registration, corporate restructuring, and dissolution and cessation of business.  He handles employment issues and liaises with government agencies like the Monetary Authority of Singapore (MAS) and the Singapore Exchange.',
  },
];

export const AuditAssuranceTeam = [
  {
    img: SzeYinAi,
    name: 'Sze Yin, Ai',
    position: 'Managing Partner',
    email: 'szeyin.ai@1m.com.sg',
    phone: '+65 8388 8851',
    intro:
      'Sze Yin graduated from National Technological University (NTU) and has more than 18 years of professional experience covering audit and assurance services to Singapore listed companies (SGX), fund management companies, overseas funds (US GAAP) and emerging companies across a wide array of industries. She has been actively involved in accounting advisory and financial due diligence for merger and acquisition transactions. Sze Yin currently sits on the board of Meyzer Group advising on its venture investments.',
    experience: [
      'Previously a principal inspector in Accounting and Corporate Regulatory Authority (ACRA), Practice Monitoring Department',
      'Represented ACRA and Singapore in overseas meetings with international and regional regulators ',
      'Led audit teams for successful listings of NASDAQ and SGX companies ',
      'Led SOX 404 reviews of major US, European and Japanese MNCs',
    ],
  },
  {
    img: ChikHanLim,
    name: 'Chik Han, Lim',
    position: 'Director, Corporate Services',
    email: 'chikhan.lim@1m.com.sg',
    phone: '+65 6336 6629',
    intro:
      'Chik Han has over 15 years of experience in providing statutory audit and internal controls audit to clients from various industries. His clients include a number of listed companies, multinational and domestic enterprises in a wide range of industries including manufacturing, construction, education, shipping, timber, plantations, IT services and trading companies.   ',
  },
];

export const FAQ = [
  {
    question: 'What are the tax reporting deadlines?',
    answer:
      'A company needs to file an estimated chargeable income 3 months after the company’s year end. The final tax computation is to be filed by the 30th of November the following year after the end of the company’s financial year end.',
  },
  {
    question: 'What are the common tax reliefs that help reduce tax bills? ',
    answer:
      'Some common tax reliefs for companies to help reduce their tax are: Tax exemption scheme for new start-up companies; Partial tax exemption for all companies; and Deduction of expenses incurred before commencement of business.',
  },
];

export const ourServices = [
  {
    title: 'Incorporation and Registration',
    description: [
      'Incorporation of private limited and public companies in accordance with the Singapore Companies Act',
      'Foreign company registration services',
      'Registration (and renewal) of representative offices',
      'Registration of businesses in Singapore',
      'Advice and application of employment and residency passes, dependant passes, and permanent residency',
    ],
  },
  {
    title: 'Provision of Local Personnel',
    description: [
      'Provision of Nominee Director services',
      'Provision of Authorised Representative (for foreign companies)',
      'Provision of Named Company Secretary',
      'Provision of Local Registered office or correspondence address',
    ],
  },
  {
    title: 'Ongoing Compliance',
    description: [
      'Audits',
      'Compilations',
      'Tax compliance',
      'GST reporting',
      'Annual returns filing',
    ],
  },
  {
    title: 'Advisory',
    description: [
      'Help with overseas company registration services ',
      'Advisory on starting up a business in Singapore and other corporate secretarial services',
      'Payroll',
      'Accounting',
    ],
  },
  {
    title: 'Deregistration & similar services',
    description: [
      'Deregistration of company',
      'Striking-off a local or foreign company',
      'Winding-up or liquidation of company',
      'Voluntary liquidation of company',
    ],
  },
];

export const FooterList = [
  { label: 'Home |', route: ROUTES.DASHBOARD },
  { label: 'Audit & Assurance |', route: ROUTES.AUDIT },
  { label: 'Corporate Secretarial |', route: ROUTES.CORPSEC },
  { label: 'Tax |', route: ROUTES.TAX },
  { label: 'Outsourcing |', route: ROUTES.OUTSOURCING },
  { label: 'Our Team |', route: ROUTES.TEAM },
  { label: 'Contact Us', route: ROUTES.CONTACTUS },
];
