import React from 'react';
import DescriptionBox from '../components/DescriptionBox/DescriptionBox';
import audit from '../assets/services/audit.jpg';
import ImageTitleList from '../components/ImageTitleList/ImageTitleList';
import MemberList from '../components/MemberList/MemberList';
import IconList from '../components/IconList/IconList';
import TitleBox from '../components/TitleBox/TitleBox';
import Dropdown from '../components/Dropdown/Dropdown';
import {
  Box,
  Container,
  Typography,
  ButtonBase,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import {
  whyWorkWithONEMillenniumPAC,
  SupportForYourBusinesses,
  AuditAssuranceTeam,
  OtherAssuranceServices,
  dotedList,
} from '../constants/list';
import useBreakpoints from '../hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  dottedrow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  dottedspan: {
    backgroundColor: 'rgba(202, 34, 40, 1)',
    display: 'block',
    width: '10px',
    height: '10px',
  },
  dottedexperienceText: {
    paddingLeft: 10,
  },
  icon: {
    paddingTop: 10,
  },
}));

const Audit = () => {
  const history = useHistory();
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <>
      <DescriptionBox
        img={audit}
        title="Audit and Assurance"
        whiteBoxHeight={100}
        header1="Business in need of audit?"
        description1={
          <p>
            As valuable a process as it is, few organisations relish an audit.
            In fact, most find it an unwelcome burden. Thankfully no-one
            understands that better than Meyzer360’s audit team. Our aim?{' '}
            <b>
              Stress-free, not stressful.  <br />
              <br /> We’ll ask the right questions to get to know your business
              well and anticipate your needs. In short, we will save you time,
              money and reputation-troubling hitches, working swiftly to spot
              potential problems before they grow into compliance deal breakers.
            </b>
          </p>
        }
      />

      <Box
        style={{
          backgroundColor: 'rgba(51, 51, 51, 1)',
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Container>
          <ImageTitleList
            headerText="Why work with Meyzer360?"
            itemData={whyWorkWithONEMillenniumPAC}
            cols={2}
            size="small"
            overlay
            gap={0}
            rowHeight={smBelow ? 250 : 450}
          />
        </Container>

        <Container style={{ paddingTop: '50px' }}>
          <IconList
            headerText={'Support for your businesses'}
            itemData={SupportForYourBusinesses}
          />
        </Container>
        {/* <Container style={{ paddingTop: '50px' }}>
          <MemberList
            headerText="Audit & Assurance Team"
            itemData={AuditAssuranceTeam}
            style={{ paddingTop: '50px' }}
          />
        </Container> */}
      </Box>

      <Box
        style={{
          backgroundColor: '#FFFFFF',
          paddingTop: 50,
          paddingBottom: 100,
        }}
      >
        <Container>
          <Dropdown
            title="FAQs"
            itemData={[
              {
                question: 'When does my company need to be audited?',
                answer: (
                  <Container>
                    <Typography variant="body1" align="left">
                      {
                        <p>
                          <b>
                            Statutory requirement to be audited
                            <br />
                          </b>
                          A company is exempted from a statutory audit if it
                          qualifies to be a small company which is defined as:
                          <br />
                          <br />
                          <b>(a)</b> A private company in the financial year in
                          question; and
                          <br />
                          <b>(b)</b> It meets at least 2 of 3 following criteria
                          for the immediate past two consecutive financial
                          years:  
                        </p>
                      }
                    </Typography>
                    {dotedList.map((text) => (
                      <Box key={text} m={1} className={classes.dottedrow}>
                        <span className={classes.dottedspan} />
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.dottedexperienceText}
                        >
                          {text}
                        </Typography>
                      </Box>
                    ))}
                    <Typography variant="body1" align="left">
                      {
                        <p>
                          Other assurance services that are carried out include:
                        </p>
                      }
                    </Typography>

                    <Box display="flex" alignItems="flex-start" flexWrap="wrap">
                      {OtherAssuranceServices.map((item) => (
                        <ButtonBase
                          key={item}
                          disableRipple
                          style={{
                            width: smBelow ? '50%' : '16.5%', // divide col by 100%
                            backgroundColor: 'transparent',
                          }}
                        >
                          <Box
                            style={{
                              border: 1,
                              borderStyle: 'groove',
                              width: '100%',
                              backgroundColor: 'rgba(125, 125, 125, 0.4)',
                            }}
                          >
                            <Box
                              flexDirection="column"
                              minHeight="15vh"
                              height="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              color="#FFFFFF"
                            >
                              {item.img}
                              <Typography
                                variant="body1"
                                className={classes.icon}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                          </Box>
                        </ButtonBase>
                      ))}
                    </Box>
                  </Container>
                ),
              },
            ]}
          />
        </Container>

        <Container
          style={{
            paddingTop: 50,
          }}
        >
          <TitleBox
            title="Contact Us"
            onClick={() => {
              history.push({
                pathname: ROUTES.CONTACTUS,
              });
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default Audit;
