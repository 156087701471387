import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#1F1F1F',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'left',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    labelReserved: {
      color: '#FFFFFF',
      flex: 'inherit',
      maxWidth: 400,
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    labelNav: {
      color: '#FFFFFF',
      flex: 'inherit',
      maxWidth: 420,
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    box: {
      backgroundColor: '#1F1F1F',
    },
  }),
);
