import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      alignItems: 'flex-start',
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: '#000000',
      opacity: 0.5,
      zIndex: 2,
    },
    gradient: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'linear-gradient(#FFFFFF00 0%, #FFFFFF00 60%, #333333 100%)',
      zIndex: 2,
      '&:hover': {
        backgroundColor: 'rgba(106, 0, 0, 0.5)',
      },
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover', //contain
      backgroundRepeat: 'no-repeat',
      zIndex: 1,
      backgroundColor: '#000000',
      backgroundPosition: 'center',
    },
    mainTitle: {
      color: '#FFFFFF',
      paddingBottom: 25,
    },
    overlayTitle: {
      color: '#FFFFFF',
      textAlign: 'unset',
      fontWeight: 'bold',
      zIndex: 3,
      whiteSpace: 'normal',
      minHeight: 120,
      [theme.breakpoints.down('sm')]: {
        minHeight: 250,
      },
    },
    title: {
      color: '#FFFFFF',
      textAlign: 'center',
      fontWeight: 'bold',
      zIndex: 3,
      whiteSpace: 'unset',
      textOverflow: 'unset',
    },
    titleBar: {
      background: 'transparent',
      zIndex: 3,
    },
    headerRoot: {
      flexGrow: 1,
      maxWidth: 500,
      alignItems: 'flex-start',
    },
  }),
);
