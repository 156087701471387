import React from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './SpanList.styles';

const SpanList = ({ itemData }) => {
  const classes = useStyles();
  return (
    <>
      {itemData.map((item) => {
        return (
          <Box key={item} className={classes.row}>
            <span className={classes.span} />
            <Typography variant="body1" align="left" className={classes.text}>
              {item}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default SpanList;
