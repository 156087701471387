import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.5,
      zIndex: -1,
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
      backgroundColor: '#7fc7d9', // Average color of the background image.
      backgroundPosition: 'center',
    },
    bgroot: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
      [theme.breakpoints.down('sm')]: {
        height: '40vh',
      },
    },
    bgcontainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(14),
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      textAlign: 'left',
    },
    content1: {
      [theme.breakpoints.between('md', 'lg')]: {
        position: 'absolute',
        left: '10px',
        right: '30px',
      },
      [theme.breakpoints.up('xl')]: {
        position: 'absolute',
        left: '155.5px',
        right: '155.5px',
      },
    },
    description1: {
      borderRadius: 0,
      padding: theme.spacing(2),
      backgroundColor: '#F0F0F0',
    },
    bgroot2: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
      [theme.breakpoints.down('sm')]: {
        height: '40vh',
      },
    },
    bgcontainer2: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
    },
    description2: {
      borderRadius: 0,
      padding: theme.spacing(2),
      backgroundColor: 'rgba(240, 240, 240, 1)',
    },
  }),
);
