import React, { useState } from 'react';
import {
  Box,
  Paper,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Tabs,
} from '@material-ui/core';
import useStyles from './MemberList.styles';
import useBreakpoints from '../../hooks/useBreakpoints';
import TitleBox from '../TitleBox/TitleBox';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MemberList = ({ itemData, headerText, showIntro }) => {
  const classes = useStyles();
  const { mdAbove } = useBreakpoints();
  const [memberIntro, setMemberIntro] = useState({
    intro: '',
    experience: '',
  });
  const [showProfile, setShowProfile] = useState(showIntro);
  const [value, setValueIndex] = React.useState();

  const handleChange = (item, index) => {
    setValueIndex(index);
    setShowProfile(true);
    if (memberIntro.memberIndex === index) {
      setShowProfile(!showProfile);
    }
    setMemberIntro({
      intro: item.intro,
      experience: item.experience,
      memberIndex: index,
    });
  };

  return (
    <>
      <Box display="flex" alignItems="flex-start">
        {!!headerText && <TitleBox title={headerText} />}
      </Box>
      <Box
        display="flex"
        alignItems={
          showIntro ? (mdAbove ? 'center' : 'flex-start') : 'flex-start'
        }
        flexDirection="column"
        className={classes.container}
      >
        <Box display="flex" alignItems="flex-start" flexDirection="row">
          <Tabs
            value={showProfile ? value : null}
            classes={{
              indicator: classes.indicator,
              scroller: classes.cardContent,
            }}
          >
            {itemData.map((item, index) => (
              <Box
                key={item}
                className={classes.box}
                onClick={() => {
                  if (showIntro) {
                    handleChange(item, index);
                  }
                }}
              >
                <Card
                  classes={{
                    root: showIntro ? classes.profilecard : classes.card,
                  }}
                >
                  <CardActionArea>
                    <CardMedia className={classes.media} image={item.img} />
                    <CardContent className={classes.area}>
                      <Typography variant="h5">{item.name}</Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ fontWeight: 600 }}
                      >
                        {item.position}
                      </Typography>
                      <Typography variant="caption">Email:</Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ wordWrap: 'break-word' }}
                      >
                        {item.email}
                      </Typography>
                      <Typography variant="caption">Phone:</Typography>
                      <Typography variant="body2" gutterBottom>
                        {item.phone}
                      </Typography>
                    </CardContent>
                    {showIntro && (
                      <CardActions>
                        <Typography
                          variant="body1"
                          style={{ textDecoration: 'underline' }}
                        >
                          {memberIntro.memberIndex == index && showProfile
                            ? 'Hide Profile'
                            : 'See Profile'}
                        </Typography>
                      </CardActions>
                    )}
                  </CardActionArea>
                </Card>
              </Box>
            ))}
          </Tabs>
        </Box>

        {showProfile && (
          <TabPanel value={value} index={value}>
            <Typography variant="body1" align="left" className={classes.intro}>
              {memberIntro.intro}
            </Typography>
            {!!memberIntro.experience && (
              <>
                <Typography
                  variant="h5"
                  align="left"
                  className={classes.experience}
                >
                  Experience
                </Typography>
                {memberIntro.experience.map((exp) => (
                  <Box key={exp} m={1} className={classes.row}>
                    <span className={classes.span} />
                    <Typography
                      variant="body1"
                      align="left"
                      className={classes.experienceText}
                    >
                      {exp}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default MemberList;
