import React from 'react';
import bgImg from '../assets/background/contactus_bg.jpeg';
import HeroContent from '../components/HeroContent/HeroContent';
import ContactForm from '../components/ContactForm';
import { Box, Container } from '@material-ui/core';

const ContactUs = () => {
  return (
    <>
      <HeroContent
        bgImg={bgImg}
        gradient
        textTitle={'Contact Us'}
        centeredTitle
      />
      <Box style={{ padding: 550, backgroundColor: '#333333' }}>
        <ContactForm />
      </Box>
    </>
  );
};

export default ContactUs;
