// import React, { useState } from 'react';
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './style.css';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TitleBox from '../components/TitleBox/TitleBox';
import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';

import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      width: '100%',
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    // border: '1px solid #ced4da',
    color: 'white',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    backgroundColor: 'rgba(255,255,255,0.2)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(0),
  },
  box: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up('xl')]: {
      padding: 80,
    },
    [theme.breakpoints.between('xs', 'lg')]: {
      padding: 40,
    },
  },

  container: {
    [theme.breakpoints.between('xs', 'lg')]: {
      position: 'absolute',
      left: '10px',
      right: '10px',
      top: '600px',
    },
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      left: '155.5px',
      right: '155.5px',
      top: '600px',
    },
  },
}));
export default function ContactForm() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        className={classes.box}
      >
        {/* <div className="formBox"> */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ textAlign: 'left', color: 'white' }}
        >
          If you&apos;d like to have a conversation with one our experts about
          how we can work together, or if you have any audit, reporting or
          compliance queries, send us a message via the form below and
          we&apos;ll be in touch shortly.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Name
              </InputLabel>
              <BootstrapInput placeholder="Name" id="bootstrap-input" />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Email
              </InputLabel>
              <BootstrapInput placeholder="Email" id="bootstrap-input" />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Phone
              </InputLabel>
              <BootstrapInput placeholder="Phone" id="bootstrap-input" />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Company Name
              </InputLabel>
              <BootstrapInput placeholder="Company Name" id="bootstrap-input" />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Subject
              </InputLabel>
              <BootstrapInput placeholder="Subject" id="bootstrap-input" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.margin} style={{ width: '100%' }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ color: 'white' }}
              >
                Message
              </InputLabel>
              <BootstrapInput
                multiline
                rows={5}
                placeholder="Enter message here..."
                id="bootstrap-input"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TitleBox
              title="Submit"
              onClick={() => {
                history.push({
                  pathname: ROUTES.CONTACTUS,
                });
              }}
            />
          </Grid>
        </Grid>
        {/* </div> */}
      </Box>
    </Container>
  );
}
