import React from 'react';
import useStyles from './DescriptionBox.styles';
import { Box, Container, Typography, Paper, Grid } from '@material-ui/core';
import useBreakpoints from '../../hooks/useBreakpoints';
import TitleBox from '../TitleBox/TitleBox';

function ContentBox2(header2, description2) {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  return (
    <Box>
      {!!header2 && (
        <Box display="flex" justifyContent="flex-end">
          <TitleBox title={header2} />
        </Box>
      )}
      <Paper className={classes.description2}>
        <Grid container spacing={2}>
          {description2.map((item) => (
            <Grid key={item} item xs={smBelow ? 12 : 6}>
              <Grid container spacing={2}>
                <Grid item>{item.icon}</Grid>
                <Grid item xs>
                  <Typography align="left" variant="h6">
                    {item.header}
                  </Typography>
                  <Typography align="left" variant="body1">
                    {item.body}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
}

const DescriptionBox = ({
  img,
  img2,
  title,
  whiteBoxHeight,
  header1,
  description1,
  header2,
  description2,
  whiteBoxHeight2,
  pushBottom,
}) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <>
      <Box className={classes.bgroot}>
        <Container className={classes.bgcontainer}>
          {title && <Typography variant="h3">{title}</Typography>}
          <div className={classes.backdrop} />
          <div
            className={classes.background}
            style={{
              backgroundImage: `url(${img})`,
            }}
          />
        </Container>
      </Box>
      {!!header1 && (
        <>
          <Box
            style={{
              backgroundColor: '#FFFFFF',
              padding: smBelow ? 0 : whiteBoxHeight,
            }}
          />
          <Container
            className={classes.content1}
            style={{ bottom: pushBottom ? pushBottom : 120 }}
          >
            {header1 && <TitleBox title={header1} />}
            <Paper className={classes.description1}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item xs>
                  <Typography align="left">{description1}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </>
      )}

      {/* second background and container */}
      {!!header2 && (
        <>
          <Box className={classes.bgroot2}>
            <Container className={classes.bgcontainer2}>
              <div
                className={classes.backdrop}
                style={{
                  height: smBelow ? '100%' : '90%',
                }}
              />
              <div
                className={classes.background}
                style={{
                  backgroundImage: `url(${img2})`,
                  height: smBelow ? '100%' : '90%',
                }}
              />
              {!smBelow && ContentBox2(header2, description2)}
            </Container>
          </Box>

          {smBelow && ContentBox2(header2, description2)}
        </>
      )}
    </>
  );
};

export default DescriptionBox;
