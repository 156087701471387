import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.5,
      zIndex: -1,
    },
    gradient: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'linear-gradient(#FFFFFF00 0%, #333333 100%)',
      zIndex: -1,
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
      backgroundColor: '#7fc7d9', // Average color of the background image.
      backgroundPosition: 'center',
    },
    bgroot: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      zIndex: 0,
      height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
      [theme.breakpoints.down('sm')]: {
        height: '120vh',
      },
    },
    bgrootCentered: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      zIndex: 0,
      height: '80vh',
      minHeight: 500,
      maxHeight: 900,
      [theme.breakpoints.down('sm')]: {
        height: '0vh',
      },
    },
    bgcontainer: {
      marginTop: theme.spacing(70),
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(40),
      },
    },
    bgcontainerCentered: {
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(20),
      },
    },
    header: {
      maxWidth: 500,
      padding: theme.spacing(2),
      backgroundColor: '#CA2228',
      color: '#FFFFFF',
    },
    headerText: {
      textAlign: 'left',
      fontSize: 25,
    },
    text: {
      textAlign: 'left',
    },
    content1: {
      position: 'absolute',
      left: '155.5px',
      right: '155.5px',
      bottom: '150px',
    },
    description1: {
      padding: theme.spacing(2),
      backgroundColor: '#F0F0F0',
    },
    content2: {
      position: 'absolute',
      left: '155.5px',
      right: '155.5px',
      top: '1700px',
    },
    description2: {
      padding: 20,
      backgroundColor: 'rgba(240, 240, 240, 1)',
    },
    icon: {
      fontSize: 70,
      paddingBottom: 10,
    },
  }),
);
