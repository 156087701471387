import React from 'react';
import useStyles from './IconListBox.styles';
import { Box, Container, ButtonBase, Typography } from '@material-ui/core';
import useBreakpoints from '../../hooks/useBreakpoints';
import TitleBox from '../TitleBox/TitleBox';

function ContentBox(itemData, title, headerText) {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  return (
    <Container>
      <Box display="flex" justifyContent="flex-end">
        {!!headerText && <TitleBox title={headerText} />}
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.title}>{title}</Typography>

        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
          {itemData.map((item) => (
            <ButtonBase
              key={item}
              disableRipple
              style={{
                padding: '5px',
                width: smBelow ? '33.3%' : '25%', // divide col by 100%
                backgroundColor: 'transparent',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  backgroundColor: 'rgba(125, 125, 125, 0.4)',
                }}
              >
                <Box
                  flexDirection="column"
                  minHeight="20vh"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="#FFFFFF"
                >
                  {item.img}
                  <Typography variant="body1" className={classes.icon}>
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

const IconListBox = ({ itemData, title, headerText, img }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <>
      <Box className={classes.bgroot}>
        <div
          className={classes.background}
          style={{
            backgroundImage: `url(${img})`,
            height: smBelow ? '100%' : '80%',
          }}
        />
        {!smBelow && ContentBox(itemData, title, headerText)}
      </Box>
      {smBelow && ContentBox(itemData, title, headerText)}
    </>
  );
};

export default IconListBox;
