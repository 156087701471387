import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2),
      backgroundColor: '#CA2228',
      background:
        'linear-gradient(#FFFFFF00 0%, #FFFFFF00 90%, rgba(0, 0, 0, 0.25) 100%)',
      color: '#FFFFFF',
      minWidth: 200,
      borderRadius: 0,
    },
    titleText: {
      textAlign: 'center',
      fontSize: 25,
    },
  }),
);
