import React from 'react';
import {
  Container,
  Box,
  Link,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import useBreakpoints from '../../hooks/useBreakpoints';
import useStyles from './Footer.styles';
import { FooterList } from '../../constants/list';
import { useHistory } from 'react-router-dom';

function NavSide() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <BottomNavigationAction
      classes={{
        root: classes.labelNav,
        label: classes.labelNav,
      }}
      label={
        <>
          {FooterList.map((item, index) => {
            return (
              <Link
                key={item}
                onClick={() =>
                  history.push({
                    pathname: item.route,
                  })
                }
                color="inherit"
              >
                &nbsp;{item.label}
              </Link>
            );
          })}
        </>
      }
    />
  );
}

function ReservedSide() {
  const classes = useStyles();
  return (
    <BottomNavigationAction
      classes={{
        root: classes.labelReserved,
        label: classes.labelReserved,
        wrapper: classes.labelReserved,
      }}
      label="© 2021 Meyzer360 All rights reserved"
    />
  );
}

const Footer = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <Box className={classes.box}>
      <Container>
        {!smBelow ? (
          <BottomNavigation showLabels className={classes.root}>
            {ReservedSide()}
            {NavSide()}
          </BottomNavigation>
        ) : (
          <BottomNavigation showLabels className={classes.root}>
            {NavSide()}
            {ReservedSide()}
          </BottomNavigation>
        )}
      </Container>
    </Box>
  );
};

export default Footer;
