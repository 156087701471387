import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    box: {
      backgroundColor: 'rgba(240, 240, 240, 1);',
      padding: 20,
    },
    root: {
      padding: 10,
    },
    accordion: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    questiontext: {
      textAlign: 'left',
      paddingLeft: 10,
    },
    span: {
      backgroundColor: 'rgba(237, 28, 36, 1)',
      display: 'block',
      width: '10px',
      height: '60px',
    },
    questionBox: {
      display: 'flex',
      alignItems: 'center',
    },
    answerBox: {
      paddingBottom: 20,
    },
    accordionSummaryRoot: {
      paddingLeft: 'unset',
    },
    content: {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
    expanded: {}, // this empty obj affected with content expanded
  }),
);
