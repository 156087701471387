import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    container: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      minHeight: '700px',
    },
    mainTitle: {
      textAlign: 'left',
      color: '#FFFFFF',
      padding: 20,
    },
    rootTab: {
      display: 'flex',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      color: '#FFFFFF',
      alignItems: 'right',
      minWidth: '374px',
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
    },
    tabItem: {
      fontSize: 15,
      height: '90px',
      //   textAlign: 'right',
      minWidth: '374px',
      //   backgroundColor: '#2A2A2A',
    },
    box: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    descriptionRow: {
      backgroundColor: 'transparent',
      padding: 10,
      maxWidth: '750px',
      minWidth: '750px',
    },
    text: {
      textAlign: 'left',
      backgroundColor: 'rgba(196, 196, 196, 0.2)',
      color: '#FFFFFF',
      padding: 20,
    },
    indicator: {
      //   backgroundColor: 'green',
      width: '10px',
    },
    selected1: {
      background: '#2A2A2A',
    },
  }),
);
