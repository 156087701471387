const ROUTES = {
  DASHBOARD: '/',
  TEAM: '/team',
  INSIGHTS: '/insights',
  CONTACTUS: '/contactus',
  TAX: '/tax',
  AUDIT: '/audit',
  CORPSEC: '/corpsec',
  OUTSOURCING: '/outsourcing',
};

export default ROUTES;
