import React from 'react';
import DescriptionBox from '../components/DescriptionBox/DescriptionBox';
import outsource from '../assets/services/outsource.jpg';
import IconList from '../components/IconList/IconList';
import IconListBox from '../components/IconListBox/IconListBox';
import TitleBox from '../components/TitleBox/TitleBox';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import {
  AccountingPayrollServices,
  FundAdministrationServices,
} from '../constants/list';
import fund_admin_bg from '../assets/background/fund_admin_bg.jpeg';
import useBreakpoints from '../hooks/useBreakpoints';

const Outsourcing = () => {
  const history = useHistory();
  const { smBelow } = useBreakpoints();

  return (
    <>
      <DescriptionBox
        img={outsource}
        title="Outsourcing"
        whiteBoxHeight={100}
        pushBottom={170}
        header1="Doing the little things for you"
        description1={
          <p>
            Our outsourcing team leaves you time to focus, drive and build your
            core business. We offer a complete range of outsourcing solutions.
          </p>
        }
      />

      <Box
        style={{
          backgroundColor: 'rgba(51, 51, 51, 1)',
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Container>
          <IconList
            headerText={'Accounting & Payroll Services'}
            itemData={AccountingPayrollServices}
          />
        </Container>
      </Box>
      <IconListBox
        title="Our experienced team of experts which includes financial, tax and legal professionals, are well-equipped to service your funds."
        headerText={'Fund Administration Services'}
        itemData={FundAdministrationServices}
        img={fund_admin_bg}
      />
      <Container
        style={{
          paddingTop: smBelow ? 50 : 80,
          paddingBottom: 50,
        }}
      >
        <TitleBox
          title="Contact Us"
          onClick={() => {
            history.push({
              pathname: ROUTES.CONTACTUS,
            });
          }}
        />
      </Container>
    </>
  );
};

export default Outsourcing;
