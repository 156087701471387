import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'flex-start',
    },
    container: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: 20,
      overflowX: 'auto',
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },
    box: {
      padding: 10,
      paddingBottom: 20,
      backgroundColor: 'transparent',
      [theme.breakpoints.down('xs')]: {
        padding: 5,
        paddingBottom: 25,
      },
    },
    card: {
      borderRadius: 0,
      backgroundColor: '#CA2228',
      color: '#FFFFFF',
      textAlign: 'left',
      width: 235,
      height: 480,
      [theme.breakpoints.down('xs')]: {
        width: 206,
      },
    },
    profilecard: {
      borderRadius: 0,
      backgroundColor: '#CA2228',
      color: '#FFFFFF',
      textAlign: 'left',
      width: 270,
      height: 480,
      [theme.breakpoints.between('sm', 'md')]: {
        width: 245,
      },
      [theme.breakpoints.down('xs')]: {
        width: 220,
      },
    },
    area: {
      height: 150,
    },
    media: {
      height: 250,
    },
    intro: {
      color: '#FFFFFF',
      padding: 10,
    },
    experience: {
      color: '#FFFFFF',
      padding: 10,
      paddingTop: 30,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    span: {
      backgroundColor: 'rgba(202, 34, 40, 1)',
      display: 'block',
      width: '10px',
      height: '10px',
    },
    experienceText: {
      color: '#FFFFFF',
      paddingLeft: 10,
      width: '100%',
    },
    indicator: {
      height: 7,
      backgroundColor: '#ED1C24',
    },
    cardContent: {
      whiteSpace: 'unset',
    },
  }),
);
