import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import useStyles from './TitleBox.styles';

const TitleBox = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="flex-start" onClick={onClick}>
      <Paper className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
      </Paper>
    </Box>
  );
};

export default TitleBox;
