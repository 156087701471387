import React from 'react';
import DescriptionBox from '../components/DescriptionBox/DescriptionBox';
import tax from '../assets/services/tax.jpg';
import invest from '../assets/services/invest.jpg';
import MemberList from '../components/MemberList/MemberList';
import TitleBox from '../components/TitleBox/TitleBox';
import Dropdown from '../components/Dropdown/Dropdown';
import SpanList from '../components/SpanList/SpanList';
import { Box, Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import { TaxTeam } from '../constants/list';
import { Icon } from '@iconify/react';

const Tax = () => {
  const history = useHistory();
  return (
    <>
      {/* <DescriptionBox
        img={tax}
        title="Tax"
        whiteBoxHeight={100}
        header1="Giving you the peace of mind"
        description1={
          <p>
            At ONEMillennium PAC, we take time to develop a thorough
            understanding of your business, your personal circumstances and your
            plans for the future before using our technical skills to provide
            the best solution to minimise your tax liabilities.{' '}
            <b>
              <br />
              <br />
              We commit to offer you a truly personal service that is of quality
              as we below in developing a long term relationship with clients –
              and confidently recommending us to their contacts and friends.
            </b>
          </p>
        }
      />
      <DescriptionBox
        img={invest}
        whiteBoxHeight={100}
        header2="Areas of tax expertise"
        description2={[
          {
            icon: discounts,
            header: 'Corporate tax and GST',
            body:
              'Our proactive tax team offers tax-efficient solutions that span all areas of corporate tax. Our clients appreciate that we base all areas of our advice on a thorough understanding of their business and personal objectives.',
          },
          {
            icon: ship,
            header: 'International tax',
            body:
              'We offer a full range of cross-border tax services ranging from transfer pricing to cross border transactions to large corporates, entrepreneurial companies and internationally mobile high net wealth investors.',
          },
        ]}
      /> */}
      <DescriptionBox
        img={tax}
        img2={invest}
        title="Tax"
        whiteBoxHeight={30}
        whiteBoxHeight2={100}
        header1="Giving you the peace of mind"
        header2="Areas of tax expertise"
        description1={
          <p>
            At ONEMillennium PAC, we take time to develop a thorough
            understanding of your business, your personal circumstances and your
            plans for the future before using our technical skills to provide
            the best solution to minimise your tax liabilities.{' '}
            <b>
              <br />
              <br />
              We commit to offer you a truly personal service that is of quality
              as we below in developing a long term relationship with clients –
              and confidently recommending us to their contacts and friends.
            </b>
          </p>
        }
        description2={[
          {
            icon: (
              <Icon
                icon="heroicons-outline:receipt-tax"
                width="55"
                height="55"
              />
            ),
            header: 'Corporate tax and GST',
            body:
              'Our proactive tax team offers tax-efficient solutions that span all areas of corporate tax. Our clients appreciate that we base all areas of our advice on a thorough understanding of their business and personal objectives.',
          },
          {
            icon: (
              <Icon
                icon="fluent:vehicle-ship-20-regular"
                width="55"
                height="55"
              />
            ),
            header: 'International tax',
            body:
              'We offer a full range of cross-border tax services ranging from transfer pricing to cross border transactions to large corporates, entrepreneurial companies and internationally mobile high net wealth investors.',
          },
        ]}
      />

      <Box
        style={{
          backgroundColor: '#FFFFFF',
          paddingTop: 100,
        }}
      >
        <Box
          style={{
            backgroundColor: 'rgba(51, 51, 51, 1)',
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          {/* <Container>
            <MemberList headerText="Tax Team" itemData={TaxTeam} />
          </Container> */}
        </Box>
        <Box
          style={{
            backgroundColor: '#FFFFFF',
            paddingTop: 50,
            paddingBottom: 100,
          }}
        >
          <Container>
            <Dropdown
              title="FAQs"
              itemData={[
                {
                  question: 'What are the tax reporting deadlines?',
                  answer: (
                    <Typography variant="body1" align="left">
                      A company needs to file an estimated chargeable income{' '}
                      <b>3 months</b> after the company’s year end. The final
                      tax computation is to be filed by the{' '}
                      <b>30th of November the following year</b> after the end
                      of the company’s financial year end.
                    </Typography>
                  ),
                },
                {
                  question:
                    'What are the common tax reliefs that help reduce tax bills? ',
                  answer: (
                    <Box styles={{ paddingBottom: 500 }}>
                      <Typography variant="body1" align="left">
                        Some common tax reliefs for companies to help reduce
                        their tax are:
                        <br />
                        <br />
                      </Typography>
                      <SpanList
                        itemData={[
                          'Tax exemption scheme for new start-up companies;',
                          'Partial tax exemption for all companies; and',
                          'Deduction of expenses incurred before commencement of business.',
                        ]}
                      />
                    </Box>
                  ),
                },
                {
                  question: 'Who should register for GST?',
                  answer: (
                    <Typography variant="body1" align="left">
                      A person must register for GST if his{' '}
                      <b>
                        taxable suppliers for the last 12 months exceeded
                        S$1million
                      </b>{' '}
                      or if he expects to make{' '}
                      <b>
                        taxable supplies exceeding S$1million in the next 12
                        months
                      </b>
                      . However a person may apply for GST registration on a
                      voluntary basis even if his{' '}
                      <b>
                        taxable supplies are not expected to exceed S$1million
                      </b>
                      . <br />
                      <br />
                      Notwithstanding that there is an obligation to register
                      for GST, the person may apply for exemption from GST
                      registration if he is making{' '}
                      <b>wholly or substantially zero-rated supplies</b>.
                    </Typography>
                  ),
                },
                {
                  question: 'What tax incentives are available in Singapore?',
                  answer: (
                    <Box styles={{ paddingBottom: 500 }}>
                      <Typography variant="body1" align="left">
                        The Singapore government offers multinational companies
                        and investors a variety of tax incentives. These include
                        <br />
                        <br />
                      </Typography>
                      <SpanList
                        itemData={[
                          'Financial sector incentives',
                          'Marine sector incentives',
                          'Global Trader Programme',
                        ]}
                      />
                      <Typography variant="body1" align="left">
                        <br />
                        Speak to us for more information on the above.
                      </Typography>
                    </Box>
                  ),
                },
              ]}
            />
          </Container>

          <Container
            style={{
              paddingTop: 50,
            }}
          >
            <TitleBox
              title="Contact Us"
              onClick={() => {
                history.push({
                  pathname: ROUTES.CONTACTUS,
                });
              }}
            />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Tax;
